/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages


                // Fixed nav
                $('header.banner').stickybits({useStickyClasses: true});
                $('.purchase-block').stickybits({
                    useStickyClasses: true,
                    stickyBitStickyOffset: 56
                });


                // Scroll to top
                $('.gotop').on('click', function(){
                    $('body,html').animate({scrollTop: 0}, 800);
                });


                // Home slider
                $('.gps-slider').slick({
                    autoplay: true,
                    arrows: false,
                    vertical: true,
                    verticalSwiping: true,
                    lazyLoad: 'progressive'
                });

                // Region slider
                $('.gps-screenshots').slick({
                    autoplay: false,
                    arrows: true,
                    dots: true,
                    prevArrow: '<div class="slider-prev icon"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.422 7.406l-4.594 4.594 4.594 4.594-1.406 1.406-6-6 6-6z"></path></svg></div>',
                    nextArrow: '<div class="slider-next"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.984 6l6 6-6 6-1.406-1.406 4.594-4.594-4.594-4.594z"></path></svg></div>'
                });


                // Image popup
                $('.gallery, .woocommerce-product-gallery__image').magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    gallery: {enabled: true},
                    mainClass: 'mfp-with-zoom',
                    zoom: {enabled: true}
                });


                // Nav subheader
                $(' .navbar-nav .dropdown-menu .submenu-header').each(function(index, el) {
                    $(this).replaceWith( "<h6 class='dropdown-header'>" + $(this).text() + "</h5>" );
                });


                // Nav hover
                $( window ).resize(function() {
                    if ($(window).width() > 769) {
                        $("#primary-menu .dropdown, .user-nav, .cart-nav").hover(function () {
                            $(this).addClass("show");
                            $(this).find('.dropdown-menu').stop(true, true).fadeIn(100).toggleClass("show");
                        }, function () {
                            $(this).removeClass("show");
                            $(this).parent().find('.dropdown-menu').stop(true, true).delay(100).fadeOut(100);
                        });
                    }

                    $("#primary-menu .dropdown-toggle").on("click", function () {
                        window.location = $(this).attr("href");
                    });
                }).resize();


                // Update History collapse
                $(".update-history .history-content:not(:nth-child(-n+4))").hide();
                $(".update-history .history-show").on('click', function(event) {
                    event.preventDefault();
                    /* Act on the event */
                    $(".update-history .history-content:not(:nth-child(-n+4))").slideToggle("fast");
                    $(".update-history").toggleClass('dropup');
                    $(this).text(function(i, text){
                        return text === "Show Earlier Updates" ? "Hide Earlier Updates" : "Show Earlier Updates";
                    });
                });


                // Searchbar
                $('#navbar-right .navbar-search .search-toggle, #navbar-right .search-form .icon-close').on('click', function(e) {
                    e.preventDefault();
                    $('#navbar-right .search-form').toggle('fast');
                });


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'single_regions': {
            init: function () {

                function debounce(fn, ms) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = setTimeout(
                            function () {
                                fn.apply(context, args);
                            },
                            ms
                        );
                    };
                }
                
                function readmoreAction() {

                    // re-calculate height
                    for (var i=0; i<readmoreAppliedElems.length; i++){
                        $(readmoreAppliedElems[i]).attr('data-height', $(readmoreAppliedElems[i]).height());
                        $(readmoreAppliedElems[i]).addClass('collapsed');
                        $(readmoreAppliedElems[i]).height(collapsedHeight);
                    }

                    otherReadMore = $('.other-read-more-click');
                    otherReadLess = $('.other-read-less-click');

                    otherReadMore.on('click', function () {
                        $(this).addClass('hide');
                        $(this).parent().find('.other-read-less-click').removeClass('hide');

                        var commentContent = $(this).parent().find('.readmore-applied');
                        commentContent.height(commentContent.attr('data-height'));
                        commentContent.removeClass('collapsed');

                        var clones = jQuery('.slick-cloned [data-comment-index="'+commentContent.attr('data-comment-index')+'"]');
                        if (clones.length){
                            clones.removeClass('collapsed');
                            clones.height(commentContent.height());
                        }
                    });
                    otherReadLess.on('click', function () {
                        $(this).addClass('hide');
                        $(this).parent().find('.other-read-more-click').removeClass('hide');

                        var commentContent = $(this).parent().find('.readmore-applied');
                        commentContent.height(collapsedHeight);
                        setTimeout(function () {
                            commentContent.addClass('collapsed');
                        }, 100);

                        var clones = jQuery('.slick-cloned [data-comment-index="'+commentContent.attr('data-comment-index')+'"]');
                        if (clones.length){
                            clones.addClass('collapsed');
                            clones.height(collapsedHeight);
                        }
                    });
                }


                var collapsedHeight = 25;


                var highlightCommentContent = $('.highlight-comment .comment-content');
                if (highlightCommentContent.height() > 100){
                    highlightCommentContent.addClass('collapsed');
                }else{
                    $('.highlight-comment .read-action').hide();
                }

                var readmoreAppliedElems = $('.readmore-applied');

                var otherReadMore = $('.other-read-more-click');
                var otherReadLess = $('.other-read-less-click');

                setTimeout(function () {
                    readmoreAction();
                }, 500);


                // readmoreAppliedElems.readmore({
                //     collapsedHeight: 25,
                //     moreLink: '<a href="javascript:;">read full review ></a>',
                //     lessLink: '<a href="javascript:;">read less review <</a>',
                //     beforeToggle: function(trigger, element, expanded) {
                //         console.log('expanded', expanded);
                //         if(expanded) { // The "Open" link was clicked
                //             // $(element).addClass('collapsed');
                //         }else{
                //             $(element).removeClass('collapsed');
                //         }
                //     },
                //     afterToggle: function(trigger, element, expanded) {
                //         if(expanded) { // The "Open" link was clicked
                //
                //         }else{
                //             $(element).addClass('collapsed');
                //         }
                //
                //         $('.slick-cloned [data-comment-index="'+($(element).attr('data-comment-index'))+'"]').height($(element).height());
                //         $('.slick-cloned [data-comment-index="'+($(element).attr('data-comment-index'))+'"]').addClass('collapsed');
                //     },
                //     // startOpen: true
                // });

                setTimeout(function(){
                    // readmoreAppliedElems.readmore('toggle');
                    // readmoreAppliedElems.addClass('collapsed');

                    var originalActiveSlide = jQuery('.slick-slide:not(.slick-cloned) [data-comment-index]');
                    for (var i=0; i<originalActiveSlide.length; i++){
                        var clones = jQuery('.slick-cloned [data-comment-index="'+originalActiveSlide[i].getAttribute('data-comment-index')+'"]');
                        if (clones.length){

                            clones.addClass('collapsed');
                            clones.height(collapsedHeight);

                            // if ($(originalActiveSlide[i]).hasClass('collapsed')){
                            //     console.log('yeah');
                            //     clones.addClass('collapsed');
                            //     clones.height(collapsedHeight);
                            // }else{
                            //     console.log('no');
                            //     clones.removeClass('collapsed');
                            //     clones.height($(originalActiveSlide[i]).height());
                            // }
                        }
                    }
                });


                window.addEventListener('resize', debounce(function() {
                    readmoreAppliedElems.removeClass('collapsed');
                    readmoreAppliedElems.height('auto');
                    setTimeout(function () {
                        readmoreAction();
                    });
                }, 250));

                var otherReviewsSlides = $('.other-reviews-slides');
                otherReviewsSlides.on("init", function() {
                    setTimeout(function () {

                    }, 100);
                });
                otherReviewsSlides.slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                infinite: true,
                                dots: true
                            }
                        },
                        {
                            breakpoint: 415,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                var highlightClickMore = $('.highlight-comment .read-more-click');
                var highlightClickLess = $('.highlight-comment .read-less-click');

                highlightClickMore.on('click', function () {
                    $(this).addClass('hide');
                    highlightClickLess.removeClass('hide');

                    $('.other-reviews').addClass('collapsed');
                    setTimeout(function () {
                        $('.highlight-comment').addClass('expand');
                    },200);
                    $('.highlight-comment .comment-content').removeClass('collapsed');
                });
                highlightClickLess.on('click', function () {
                    $(this).addClass('hide');
                    highlightClickMore.removeClass('hide');

                    $('.highlight-comment').removeClass('expand');
                    $('.highlight-comment .comment-content').addClass('collapsed');
                    setTimeout(function () {
                        $('.other-reviews').removeClass('collapsed');
                    }, 400);
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
